import React from "react"
import { Layout } from "../components/layout"
import { motion, useViewportScroll, useTransform, useSpring } from "framer-motion"
import logo from "../images/introLogo.svg"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/linkResolver'
import Moment from "moment"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Button from "../components/button"

const { scrollYProgress } = useViewportScroll();

export const query = graphql`
query indexQuery {
  image: file(relativePath: {eq: "introImage.png"}) {
    id
    childImageSharp {
      fixed {
        src
        srcSet
      }
      fluid {
        src
        srcSet
      }
    }
  }
    prismic {
        allIssues(sortBy: literal_release_date_DESC) {
      edges {
        node {
          cover_imageSharp {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          identifier
          issue_theme
          literal_release_date
          release_date
          contributors
          _meta {
            type
            uid
          }
          cover_image
        }
      }
    }
        allPosts(sortBy: meta_firstPublicationDate_DESC) {
          edges {
            node {
              post_body
              title
              _meta {
                firstPublicationDate
                uid
                type
              }
              author {
                ... on PRISMIC_Staff {
                  first_name
                  _meta {
                      type
                    uid
                  }
                }
              }
              guest_author
            }
          }
        }
      }
  }
`
const damping = 200;
const stiffness = 0;
const firstParagraph = (post => {
  let firstTextSlice = post.text;
  if (firstTextSlice != null) {
    const textLimit = 300
    let text = firstTextSlice
    let limitedText = text.substring(0, textLimit)
    if (text.length > textLimit) {
      return (
        <p>{limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...'}</p>
      );
    } else {
      return <p>{text}</p>;
    }
  } else {
    return null;
  }
})
const HomePage = ({ data }) => {
  return (
    <div className="body">
      <Layout>
        <SEO title="Home" />
        <motion.section style={{ scale: useSpring(useTransform(scrollYProgress, [0, 0.1], [0.5, 1]), { damping: { damping } }, { stiffness: { stiffness } }), y: useSpring(useTransform(scrollYProgress, [0, 0.1], [-200, 300]), { damping: { damping } }, { stiffness: { stiffness } }), rotateZ: useSpring(useTransform(scrollYProgress, [0, 0.1], [30, 0]), { damping: { damping } }, { stiffness: { stiffness } }) }} data-w-id="96720f02-6a69-f349-b149-363945e1e5b9" className="section introduction">
          <section className="intro-container">
            <img src={logo} alt="The Pub Logo" className="intro-title" />
            <h2 className="intro-subtitle">Wheaton&#x27;s Independent Undergraduate <br />Academic Journal</h2>
            <h1 className="subtitle-mobile">Wheaton&#x27;s Independent<br />Undergraduate<br />Academic Journal</h1>
          </section>
          <div className="div-block">
            <div data-w-id="894e373a-f161-86db-cc59-e8f325f3caf5" className="intro-image-parent"><Img fluid={data.image.childImageSharp.fluid} className="intro-image" alt="The Pub Magazines" /></div>
            <div data-w-id="cc04477d-2c45-abc6-36ff-bceec99fac74" className="intro-items">
              <h2 id="mission" className="intro-paragraph"><em>The Pub</em> is an <span className="highlighted-text">independent</span> journal, <span className="highlighted-text">unhindered</span> by institutional expectations, and designed to fill the spaces that other campus publications leave empty. Put simply, <em>The Pub</em> is by the students, for the students.</h2>
              <div className="button-parent intro">
                <Button name="About The Pub" to="/about/" dark />
              </div>
            </div>
          </div>
        </motion.section>
        <section data-w-id="96720f02-6a69-f349-b149-363945e1e5b9" className="section introduction mobile">
          <section className="intro-container">
            <img src={logo} alt="The Pub Logo" className="intro-title" />
            <h2 className="intro-subtitle">Wheaton&#x27;s Independent Undergraduate <br />Academic Journal</h2>
            <h1 className="subtitle-mobile">Wheaton&#x27;s Independent<br />Undergraduate<br />Academic Journal</h1>
            <div className="button-parent intro">
              <Link to="/submissions/">
                <div className="simple-button dark w-inline-block">Submit Work</div>
              </Link>
              <Button name="Latest Issue" to="/issues/fall-2023/" dark />
            </div>
          </section>
          <div className="div-block">
            <div data-w-id="894e373a-f161-86db-cc59-e8f325f3caf5" className="intro-image-parent"><Img fluid={data.image.childImageSharp.fluid} alt="Previous Pub Magazines" className="intro-image" /></div>
            <div data-w-id="cc04477d-2c45-abc6-36ff-bceec99fac74" className="intro-items">
              <h2 id="mission" className="intro-paragraph"><em>The Pub</em> is an <span className="highlighted-text">independent</span> journal, <span className="highlighted-text">unhindered</span> by institutional expectations, and designed to fill the spaces that other campus publications leave empty. Put simply, <em>The Pub</em> is by the students, for the students.</h2>
              <div className="button-parent intro">
                <Button name="About The Pub" to="/about/" dark />
              </div>
            </div>
          </div>
        </section>
        <div className="section spacer"></div>
        <section className="section _2nd">
          <div className="features">
            <div>
              <div className="page-heading-parent home">
                <h1>Latest Issues</h1>
                <div className="line grey"></div>
                {data.prismic.allIssues.edges.slice(0, 4).map(({ node: issue }, i) => (
                  <div key={i} className="preview issues home">
                    <div id="w-node-ba5dcb699972-77fb1818" className="preview">
                      <Link to={linkResolver(issue._meta)}>
                        <div className="text-link w-inline-block">
                          <h2 className="heading">{issue.issue_theme[0].text}</h2>
                        </div>
                      </Link>
                      <h4>Contributors:</h4>
                      <p>{issue.contributors[0].text}</p>
                    </div>
                    <Link to={linkResolver(issue._meta)} className="card w-inline-block">
                      <Img fluid={issue.cover_imageSharp.childImageSharp.fluid} alt="" className="card-image" />
                      <div className="card-info">
                        <h4>{issue.identifier[0].text}</h4>
                        <div>{issue.release_date[0].text}</div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="page-heading-parent home">
                <h1>Recent Posts</h1>
                <div className="line grey"></div>
              </div>
              {data.prismic.allPosts.edges.slice(0, 3).map(({ node: post }, i) => (
                <div key={i} className="preview">
                  <Link to={linkResolver(post._meta)}>
                    <div className="text-link w-inline-block">
                      <h2 className="heading">{post.title[0].text}</h2>
                    </div>
                  </Link>
                  <p>{firstParagraph(post.post_body[0])}</p>
                  <div className="post-details">
                    {
                      post.author != null ?
                        <div><Link to={linkResolver(post.author._meta)} className="text-link"><strong>{post.author.first_name[0].text}</strong></Link> on {Moment(post._meta.firstPublicationDate).format("LL")}</div>
                        :
                        post.guest_author != null ?
                          <div><strong>{post.guest_author[0].text}</strong> on {Moment(post._meta.firstPublicationDate).format("LL")}</div>
                          :
                          <div><strong>Anonymous</strong> on {Moment(post._meta.firstPublicationDate).format("LL")}</div>
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}
export default HomePage
